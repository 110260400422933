import Cookies from 'universal-cookie';
import { jwtDecode } from 'jwt-decode';

import { doLogout } from '../utils/context/auth';
import { ENDPOINT_REFRESHTOKEN } from '../libs/constantes'

const cookies = new Cookies();

export async function refreshTokenIfNeeded(loggedUser, dispatch) {
    if (!loggedUser || !loggedUser.token) return;
    
    const token = loggedUser?.token;
    
    if (isTokenExpired(token)) {
        const refreshToken = loggedUser.refreshToken;
        const response = await fetch(ENDPOINT_REFRESHTOKEN, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: refreshToken }),
        });

        if (response.ok) {
            const data = await response.json();
            loggedUser.token = data.token;

            const cookieValue = cookies.get('user') ? cookies.get('user') : null

            const resolvedStatus = {
                status: (cookieValue?.status === 'resolved') ? cookieValue.status : 'idle',
                user: loggedUser,
                error: null,
                isCookie: (cookieValue?.status === 'resolved'),
                userPrefs: {
                    autoSearch : (cookieValue?.userPrefs?.autoSearch === true),
                    editionMode : (cookieValue?.userPrefs?.editionMode === true),
                }
              }
              
              dispatch(resolvedStatus);    
              cookies.set('user', JSON.stringify(resolvedStatus), { 
                path: '/', 
                maxAge: 86400, 
                secure: true, 
                sameSite: 'lax' 
              });

            return true;
        } else {
            console.error('Échec du rafraîchissement du token');
            doLogout(dispatch);
            return false;
        }
    }
    return true;
}
  
function isTokenExpired(token) {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
}
